import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    //页面路径
    path:"/Yield",
    //组件名称
    name:"Yield",
    component:() => import('../views/Yield.vue'),
  },
  {
    //页面路径
    path:"/StockRemind",
    //组件名称
    name:"StockRemind",
    component:() => import('../views/StockRemind.vue'),
  },
  {
    //页面路径
    path:"/GrabWe",
    //组件名称
    name:"GrabWe",
    component:() => import('../views/GrabWe.vue'),
  },
  // // 一级路由
  // {
  //   //组件路径
  //   path:"/main",
  //   //组件名称
  //   name:"Main",
  //   component:() => import('../views/Main.vue'),
  //   // 二级路由
  //   children:[
  //     {
  //       //页面路径
  //       path:"home",
  //       //组件名称
  //       name:"Home",
  //       component:() => import('../views/Home.vue'),
  //     },
  //   ]
  // },
  // 路由重定向
  {
    path:"*",
    redirect:{
      name:"Yield"
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
