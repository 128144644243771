<template>
  <div id="app">
    <router-view></router-view>
    <!-- 加载款 -->
    <van-overlay :z-index="9999" :show="this.$store.state.landingState">
      <!-- @click.stop是其中的一个修饰符，它的作用是阻止事件冒泡，即阻止事件向父级元素传递 -->
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#1989fa" size="60" text-size="16"></van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading } from 'vant';
import { Overlay } from 'vant';

Vue.use(Overlay);
Vue.use(Loading);

export default {
  data() {
    return {
      loadingState: false,
    }
  }, methods: {
    loadingShow() {
      this.loadingState = true;
    },
    loadingClose() {
      this.loadingState = false;
    }
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

/* 加载款居中 */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
