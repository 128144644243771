import Vue from 'vue'
import Vuex from 'vuex'



Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    //全局共享的数据
    landingState:false,//加载款状态
    

  },
    // 在store对象中增加getters属性
    // getters: {
    //   getMessage(state) {
    //     // 获取修饰后的name，第一个参数state为必要参数，必须写在形参上
    //     return `hello${state.name}`;
    //   },
    // },
    //修改state的全局值(只能在这个方法修改)
  mutations: {
    // 增加nutations属性
    changelandingState(state,Status) {
      // 增加一个mutations的方法，方法的作用是让num从0变成5，state是必须默认参数
      state.landingState = Status;
    },
    //Actions存在的意义是假设你在修改state的时候有异步操作,专门画个区域放
    // actions: {
      
    //   // setNum(content) {
    //   //   // 增加setNum方法，默认第一个参数是content，其值是复制的一份store
    //   //   return new Promise(resolve => {
    //   //     // 我们模拟一个异步操作，1秒后修改number为888
    //   //     setTimeout(() => {
    //   //       content.commit('setNumberIsWhat', { number: 888 });
    //   //       resolve();
    //   //     }, 1000);
    //   //   });
    //   // },

    //   //使用: await this.$store.dispatch('setNum', { number: 611 });

    // },
  },

})


export default store;